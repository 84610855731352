import PrevAxios from '../axiosProvider'
import { getToken } from '../basic'

const { API_HOST } = process.env

interface DownloadPdfProps {
  filename: string
  html: string
  params?: any
}

export const downloadPdf = async (props: DownloadPdfProps): Promise<any> => {
  const localToken = getToken()
  const payload: DownloadPdfProps = {
    filename: props.filename,
    html: props.html
  }
  if (props.params) payload.params = props.params
  const response = await PrevAxios({
    method: 'post',
    url: `${API_HOST}download-converter/pdf`,
    data: payload,
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${localToken ?? ''}`
    }
  })
  return response
}
