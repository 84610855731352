export const getCookie = (cname: string): string | null => {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return null
}

export const setCrossSubdomainCookie = (name: string, value: string, days: number): void => {
  const assign = name + '=' + escape(value) + ';'
  const d = new Date()
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000)
  const expires = 'expires=' + d.toUTCString() + ';'
  const path = 'path=/;'

  let domain = 'domain=prev.app'
  if (window.document.domain.includes('prevteste.com')) {
    domain = 'domain=prevteste.com'
  } else if (window.document.domain.includes('prev2')) {
    domain = 'domain=prev2.app'
  } else if (window.document.domain.includes('prevfeature')) {
    domain = 'domain=prevfeature.app'
  } else if (window.document.domain.includes('localhost')) {
    domain = `domain=${window.document.domain}`
  } else if (process.env.REACT_APP_PREV_ENV === 'homolog') {
    domain = 'domain=prevhml.app'
  } else if (window.document.domain.includes('prev-dashboard-v2.s3-website-us-east-1.amazonaws.com')) {
    domain = 'domain=prev-dashboard-v2.s3-website-us-east-1.amazonaws.com'
  }
  document.cookie = assign + expires + path + domain
}
