import { type AxiosError } from 'axios'

interface ErrorResponse {
  error_description?: string
}

export class ApiError extends Error {
  public statusCode: number
  public message: string
  constructor (axiosError: AxiosError) {
    super()
    this.statusCode = axiosError?.status ?? 500
    const errorData = axiosError?.response?.data as ErrorResponse
    this.message = errorData?.error_description ?? 'Algo deu errado, tente novamente mais tarde'
  }
}
