import PrevAxios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiError } from '../error';

const whiteListUrls = ['https://api.homolog.prev.app/v1/oauth/token', 'https://api.homolog.prev.app/v1/contas/senha', 'https://api.homolog.prev.app/v1/contas/senha', "api.mapbox.com", "https://youtube.googleapis.com/"]

let process = true
let queueQuantity = 0

export const activateInterceptors = (setRelogin: (status: boolean) => void) => {
  // Interceptor de requisição
  PrevAxios.interceptors.request.use((config) => {
    if (!process && !whiteListUrls.some(url => config.url?.includes(url)) && queueQuantity <= 15) {
      // Retorna uma Promise que aguarda o `process` ser `true`
      return new Promise((resolve, reject) => {
        queueQuantity = queueQuantity + 1
        const waitForProcess = setInterval(() => {
          if (process) {
            clearInterval(waitForProcess); // Para de verificar quando o process é true
            queueQuantity = queueQuantity - 1
            const newToken = localStorage.getItem("access_token_prev")
            if(newToken) {
              config.headers = config.headers || {}
              config.headers['Authorization'] = `Bearer ${newToken}`;
            }
            resolve(config)
          }
        }, 1000); // Verifica a cada 100ms
      });
    }
    return config; // Se process já é true, simplesmente retorna a requisição normalmente
  }, (error) => Promise.reject(error));


  // Interceptor de resposta
  PrevAxios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config;

      const response = error.response
      console.log("response", response)

      if(!originalRequest) return Promise.reject('Sem config de erro')

      if (error.response?.status === 401 && process && !whiteListUrls.some(url => originalRequest.url?.includes(url))) {
        setRelogin(true); // Ativa o modal de relogin
        process = false; // Pausa novas requisições
      
        const oldToken = localStorage.getItem("access_token_prev");
        let newToken = localStorage.getItem("access_token_prev");
      
        // Aguardar o fechamento do modal de relogin e a troca do token
        await new Promise<void>((resolve) => {
          const interval = setInterval(() => {
            newToken = localStorage.getItem("access_token_prev");
            if (newToken !== oldToken) { // Quando o token for alterado (relogin feito)
              process = true; // Permite novas requisições
              clearInterval(interval); // Para o monitoramento
              setRelogin(false); // Fecha o modal de relogin
              resolve(); // Resolva a promessa e permita que o fluxo continue
            }
          }, 1000); // Verifica a cada 1 segundo
        });
      
        // A requisição original será reprocessada aqui, pois o fluxo da função vai continuar
        if(originalRequest) {
          const newResponse = await PrevAxios({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${newToken}`,
            },
          });
          return newResponse
        }
      } else if(error.response?.status === 401 && !whiteListUrls.some(url => originalRequest.url?.includes(url))) {
        const oldToken2 = localStorage.getItem("access_token_prev");
        let newToken2 = localStorage.getItem("access_token_prev");
        await new Promise<void>((resolve) => {
          queueQuantity = queueQuantity + 1
          const interval = setInterval(() => {
            console.log("quantidade na fila atual", queueQuantity)
            newToken2 = localStorage.getItem("access_token_prev");
            if (newToken2 !== oldToken2) { // Quando o token for alterado (relogin feito)
              clearInterval(interval); // Para o monitoramento
              queueQuantity = queueQuantity - 1
              resolve(); // Resolva a promessa e permita que o fluxo continue
            }
          }, 1000); // Verifica a cada 1 segundo
        });
        if(originalRequest) {
          const newResponse = await PrevAxios({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${newToken2}`,
            },
          });
          return newResponse
        }
      }
      const prevApiError = new ApiError(error);
      console.error('API Error:', prevApiError);
      return await Promise.reject(prevApiError);
    }
  );
};

export default PrevAxios;