// import { type JobsInterface } from './types'
// import { authGet } from '@prev-front/api-adapter'

import { authGet, getCookie } from '@prev-front/api-adapter'
import { API_HOST_DASHBOARD_V2 } from './constants'
// import qs from 'qs'


// type Params = Record<string, any>

// export async function getJobs (params: Params): Promise<JobsInterface[] | null> {
//   try {
//     const queryString = qs.stringify(params, { addQueryPrefix: true, skipNulls: true })
//     const response = await authGet(`/profissoes${queryString}`, API_HOST_DASHBOARD_V2)
//     if (response) {
//       return response as JobsInterface[]
//     } else {
//       throw new Error('Resposta vazia do servidor.')
//     }
//   } catch (error) {
//     console.error(error)
//     throw error
//   }
// }

interface Usuario {
  id: number
  uuid: string
  nome: string
  email: string
  cpf: string
}

interface Assinatura {
  id: number
  uuid: string
  cpf: string
  acesso: string
  status: string
  intervalo: string
  meioDePagamento: string
  trial: boolean
  dataDaProximaCobranca: string
  dataDeCriacao: string
  cartaoDeCredito: CartaoDeCredito
  periodo: Periodo
  tipoEmissaoNotaFiscal: string
  tiposDeModulo: [any]
  numeroDeParcelas?: number
}

interface CartaoDeCredito {
  nome: string
  dataDeVencimento: string
  bandeira: string
  primeirosSeisDigitos: string
  ultimosQuatroDigitos: string
}

interface Periodo {
  id: number
  uuid: string
  inicio: string
  fim: string
  dataDaCobranca: string
  dataDeAtualizacao: string
  dataDeCriacao: string
  motivo: string
  tiposDeModulo: string[]
}

interface Competencia {
  id: number
  nome: string
  estado: Estado
  descricao: string
}

interface Especialidade {
  id: number
  descricao: string
}

interface Estado {
  id: number
  nome: string
  sigla: string
  regiao: string
  codigoIbge: string
  pais: Pais
}

interface Pais {
  id: number
  nome: string
  sigla: string
}

interface Escritorio {
  id: number
  uuid: string
  numeroDeFuncionarios: number
  telefone: string
  telefoneSecundario: string
  descricao: string
  email: string
  urlImagemEscritorioExibicao: string
  slugCatalogo: string
  competenciaEstadual: Competencia
  competenciaFederal: Competencia
  cidadesDeAtuacao: Competencia[]
  especialidades: Especialidade[]
  nome: string
  slug: string
  cnpj: string
  membros: Membro[]
  endereco: Endereco
  ativoCatalogo: boolean
}

interface Endereco {
  id: number
  uf: string
  cidade: string
  cep: string
  endereco: string
  bairro: string
  numero: string
  complemento?: string
  latitude?: number
  longitude?: number
}

interface Membro {
  id: number
  usuario: Usuario
  nome: string
  email: string
  urlImagemPerfil: string
  numeroDaOAB: string
  profissao: string
  privilegio: string
  status: string
  dataDeCriacao: string
  dataDoUltimoAcesso: string
}

export interface UserInfoResponse {
  usuario: Usuario
  escritorio: Escritorio
  assinatura: Assinatura
}

export const getUserInfo = async (): Promise<UserInfoResponse | null> => {
  const cookie = getCookie("prev_session");
  let prev_session = {};
  if (cookie) {
    prev_session = { "X-Global-Session-User-ID": cookie };
  }
  const response = await authGet('/info', API_HOST_DASHBOARD_V2, {
    headers: {
      ...prev_session
    }
  })
  return response as UserInfoResponse
}
